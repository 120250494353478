import React from 'react'
import { Link } from 'gatsby'
import { ChevronLeft, ChevronRight } from 'react-feather'

const Pagination = ({ pageContext, pathPrefix }) => {
  const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } = pageContext

  if(previousPagePath || nextPagePath) {
    return (
      <figure className="figure-pagination figure-pagination--jobs" role="navigation">
        <nav>
          {previousPagePath ? (
            <Link className="link" to={previousPagePath}><ChevronLeft /><span>Previous</span></Link>
          ) : (
            <span className="link link--disabled"><ChevronLeft /><span>Previous</span></span>
          )}
          <div>Page {humanPageNumber} of {numberOfPages}</div>
          {nextPagePath ? (
            <Link className="link" to={nextPagePath}><span>Next</span><ChevronRight /></Link>
          ) : (
            <span className="link link--disabled"><span>Next</span><ChevronRight /></span>
          )}
        </nav>
      </figure>
    )
  } else {
    return null;
  }
}

export default Pagination
