import React from 'react'
import { Link } from 'gatsby'
import { ChevronRight } from 'react-feather'
import TfttSquare from '../../assets/images/logos/tfttsquare.svg'

import { formatDateAsHumanized, formatDateAsShort, unescapePunctuation } from '../../utilities/formatters'

const PostStandard = (props) => {
  const post = props.post;
  const media_url = post.featured_media ? post.featured_media.source_url : TfttSquare;
  const tags = post.tags || [];
  const categories = post.categories || [];
  const parsed_date = new Date(post.date);
  return (
    <figure className="post post--standard">
      {media_url &&
        <aside>
          <Link to={post.path} className="post__image" style={{"backgroundImage": `url(${media_url})`}} />
        </aside>
      }
      <main>
        <section>
          <Link to={`${post.path}`}>
            <h3>{unescapePunctuation(post.title)}</h3>
          </Link>
          <small>By <Link to={`/author/${post.author.slug}`}>{post.author.name}</Link></small>
          <span className="text--spacer">•</span>
          <small>{formatDateAsHumanized(parsed_date)}</small>
        </section>
        <section>
          {!!categories.length &&
            <ul className="post__tag-category post__tag-category--category">

              {categories.map((category, index) => {
                return (<li key={category.slug}><Link to={`/categories/${category.slug}`}>{category.name}</Link></li>)
              })}
            </ul>
          }
          {post.excerpt &&
            <div className="post__excerpt"
              dangerouslySetInnerHTML={{
                __html: post.excerpt.replace(/<p class="link-more.*/, ''),
              }}
            />
          }
          {!!tags.length &&
          <ul className="post__tag-category post__tag-category--tag">
            <li><span className="tag-category__head">Tagged:</span></li>
            {tags.map((tag, index) => {
              return (<li key={tag.slug}><Link to={`/tags/${tag.slug}`}>{tag.name}</Link></li>)
            })}
          </ul>
          }
        </section>
      </main>
      <Link to={`${post.path}`} className="post__read-link"><ChevronRight /></Link>
    </figure>
  )
}

export default PostStandard
