import React from 'react'
import Tile from './tile'


const TileGrid = (props) => {

    return (
      <figure className="grid--index">
        {[...Array(props.size)].map((e, i) =>
          <Tile key={i} palette={props.palette} ratio={props.ratio}/>
        )}
        {props.hasTitle && <div className="grid__title"><div>{props.children}</div></div>}
        {props.hasFigure && props.children}
      </figure>
    )

}

export default TileGrid
