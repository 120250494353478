import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import PostStandard from './index/post-standard'

export default class PostList extends React.Component {
  render() {
    const { posts, title } = this.props

    return (
      <section className="list list--posts">
          {title && <h3 className="list__title">{title}</h3>}
          {posts.map(({ node: post }) => (
            <PostStandard key={post.id} post={post} />
          ))}
      </section>
    )
  }
}

PostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    excerpt
    tags {
      name
      slug
    }
    categories {
      name
      slug
    }
    featured_media {
      source_url
    }
    author {
      name
      slug
    }
    path
    date(formatString: "MMMM DD, YYYY")
    slug
  }
`
